import React from "react";
import Layout from "../components/layout";
import Button from "../components/Button";
// import Modal from "../components/Modal";
// import TabView from "../components/TabView";
// import Collapse from "../components/Collapse";
// import NumberInput from "../components/NumberInput";
import useSnackBars from "../hooks/useSnackBars";

export default function Sink(props) {
  // const [modalState, toggleModalState] = useState(false);
  const { addAlert } = useSnackBars();

  return (
    <Layout
      location={props.location}
      render={(context) => {
        return (
          <>
            <Button onClick={() => addAlert("test the snackbar")}>
              test the snackbar
            </Button>
            {/* <NumberInput
              onChange={(val) => console.log(val)}
              className="margin-6"
              // min={1} 1 is the default min value
              max={10}
              defaultValue={2}
            />
            <input type="number" /> */}

            {/* <Button theme="dark" onClick={() => toggleModalState(true)}>
              <span>Toggle Modal</span>
            </Button>
            <Modal isOpen={modalState} onClose={() => toggleModalState(false)}>
              <h1 className="marginTop-0 c-white">Modal Content</h1>
            </Modal>
            <h1>Tab View </h1>
            <TabView>
              <div label="First Tab">
                <h1>This is title of the first tab item content</h1>
                <p>This is the paragraph content</p>
              </div>
              <div label="Second Tab">
                <h2>You made it to the second tab!</h2>
                <p>Wow. I'm really proud of you!</p>
                <Button onClick={() => alert("SIKE!")}>Click here!</Button>
              </div>
            </TabView>
            <Collapse
              className="outline"
              buttonContents={() => (
                <h1 class="button--secondary">Button Label or other html</h1>
              )}
            >
              <h1>Content to be shown</h1>
            </Collapse> */}
          </>
        );
      }}
    />
  );
}
